import { FC, KeyboardEvent, MouseEvent, useEffect } from 'react';
import { FormattedMessage, IntlFormatters, useIntl } from 'react-intl';
import {
    Button,
    Card,
    Col,
    Divider,
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Row,
    Typography,
    Modal,
    Spin,
} from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import formMessages from '../../i18n/formMessages';
import LabelWithTooltipIcon from '../../components/LabelWithTooltipIcon';
import CountrySelect from '../../components/CountrySelect';
import TitleAlt from '../../components/TitleAlt';
import PhoneInput from '../../components/PhoneInput';
import ButtonIcon from '../../components/ButtonIcon';
import RequiredFields from '../../components/RequiredFields';
import { classNames } from '../../helpers';
import {
    create as createFactory,
    update as updateFactory,
    details as factoryDetails,
    getFactoriesDetailsState,
    getFactoriesUpdateState,
    getFactoriesCreateState,
} from '../../store/actions/factories';
import { useActions, usePrevious } from '../../hooks';
import { useSelector } from 'react-redux';
import { errorMessage, successMessage } from '../../helpers/message';
import genericMessages from '../../i18n/genericMessages';
interface FactoryFormDrawerProps extends DrawerProps {
    factoryId?: string;
}

const getWorkerFields = (formatMessage: IntlFormatters['formatMessage']) => [
    {
        name: 'nbWorkers',
        label: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.title.nbWorkers',
            defaultMessage: 'Nombre total travailleurs',
            description: 'label',
        }),
        tooltip: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.tooltip.nbWorkers',
            defaultMessage: 'Veuillez communiquer le chiffre à date',
            description: 'tooltip',
        }),
    },
    {
        name: 'nbPermanentWorkers',
        label: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.title.nbPermanentWorkers',
            defaultMessage: 'Nombre de travailleurs permanents',
            description: 'label',
        }),
        tooltip: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.tooltip.nbPermanentWorkers',
            defaultMessage: "Nombre de travailleurs dans l'usine à date",
            description: 'tooltip',
        }),
    },
    {
        name: 'nbTemporaryWorkers',
        label: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.title.nbTemporaryWorkers',
            defaultMessage: 'Nombre de travailleurs temporaires',
            description: 'label',
        }),
        tooltip: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.tooltip.nbTemporaryWorkers',
            defaultMessage: "Nombre de travailleurs sous contrat temporaire dans l'usine à date",
            description: 'tooltip',
        }),
    },
    {
        name: 'nbHomeWorkers',
        label: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.title.nbHomeWorkers',
            defaultMessage: 'Nombre de travailleurs à domicile',
            description: 'label',
        }),
        tooltip: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.tooltip.nbHomeWorkers',
            defaultMessage: 'Nombre de travailleurs effectuant 100% de leurs tâches depuis leur domicile',
            description: 'tooltip',
        }),
    },
    {
        name: 'nbInternationalWorkers',
        label: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.title.nbInternationalWorkers',
            defaultMessage: 'Nombre de travailleurs immigrés',
            description: 'label',
        }),
        tooltip: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.tooltip.nbInternationalWorkers',
            defaultMessage:
                "Une personne qui migre d'un pays à un autre dans le but d'être employé autrement qu'à son propre compte",
            description: 'tooltip',
        }),
    },
    {
        name: 'nbYoungWorkers',
        label: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.title.nbYoungWorkers',
            defaultMessage: 'Nombre de travailleurs jeunes (16-18ans)',
            description: 'label',
        }),
        tooltip: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.tooltip.nbYoungWorkers',
            defaultMessage: 'Nombre de travailleurs entre 16 et 18 ans',
            description: 'tooltip',
        }),
    },
    {
        name: 'nbNightWorkers',
        label: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.title.nbNightWorkers',
            defaultMessage: 'Nombre de travailleurs de nuit',
            description: 'label',
        }),
        tooltip: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.tooltip.nbNightWorkers',
            defaultMessage:
                "Nombre de travailleurs ayant des horaires considérés comme nocturnes par le Code du Travail du pays dans lequel se trouve l'usine",
            description: 'tooltip',
        }),
    },
    {
        name: 'nbPermanentWorkersLeaving',
        label: formatMessage(
            {
                id: 'factoty_form_drawer.getWorkerFields.title.nbPermanentWorkersLeaving',
                defaultMessage: 'Nombre de départ de travailleurs permanents en {year}',
                description: 'label',
            },
            { year: dayjs().subtract(1, 'year').get('year') }
        ),
        tooltip: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.tooltip.nbPermanentWorkersLeaving',
            defaultMessage:
                "Nombre de travailleurs permanents ayant quitté votre entreprise au cours de l'année précédente",
            description: 'tooltip',
        }),
    },
    {
        name: 'nbNewPermanantsWorkers',
        label: formatMessage(
            {
                id: 'factoty_form_drawer.getWorkerFields.title.nbNewPermanantsWorkers',
                defaultMessage: "Nombre d'arrivée de travailleurs permanents en {year}",
                description: 'label',
            },
            { year: dayjs().subtract(1, 'year').get('year') }
        ),
        tooltip: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.tooltip.nbNewPermanantsWorkers',
            defaultMessage:
                "Nombre de travailleurs permanents ayant rejoint votre entreprise au cours de l'année précédente",
            description: 'tooltip',
        }),
    },
    {
        name: 'nbWorksBeginYear',
        label: formatMessage(
            {
                id: 'factoty_form_drawer.getWorkerFields.title.nbWorksBeginYear',
                defaultMessage: 'Nombre total de travailleurs présents au 1er Janvier {year}',
                description: 'label',
            },
            { year: dayjs().subtract(1, 'year').get('year') }
        ),
        tooltip: formatMessage({
            id: 'factoty_form_drawer.getWorkerFields.tooltip.nbWorksBeginYear',
            defaultMessage: "Nombre de travailleurs de l'usine au 1er janvier de l'année précédente",
            description: 'tooltip',
        }),
    },
];

const FactoryFormDrawer: FC<FactoryFormDrawerProps> = ({ factoryId, onClose, ...props }) => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [create, update, loadFactory] = useActions([
        createFactory.trigger,
        updateFactory.trigger,
        factoryDetails.trigger,
    ]);
    const factoryDetailState = useSelector(getFactoriesDetailsState);
    const factoryUpdateState = useSelector(getFactoriesUpdateState);
    const factoryCreateState = useSelector(getFactoriesCreateState);
    const previous = usePrevious({ factoryDetailState, factoryUpdateState, factoryCreateState });
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        if (factoryId) {
            update({ ...values, id: factoryId });
        } else {
            create(values);
        }
    };
    const onAfterVisibleChange: DrawerProps['afterVisibleChange'] = (visible) => {
        if (!visible) {
            form.resetFields();
        }
    };
    const onBeforeClose = () => {
        if (form.isFieldsTouched()) {
            Modal.confirm({
                title: formatMessage({
                    id: 'factoty_form_drawer.before_close.title',
                    defaultMessage: "Êtes-vous sûr de vouloir quitter l'ajout / édition d'usine ?",
                    description: 'close drawer factory',
                }),
                icon: <ExclamationCircleOutlined />,
                content: formatMessage({
                    id: 'factoty_form_drawer.before_close.info',
                    defaultMessage: 'Les informations saisies seront perdues',
                    description: 'close drawer factory contente',
                }),
                onOk() {
                    onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
                },
                okText: formatMessage({
                    id: 'factoty_form_drawer.before_close.ok',
                    defaultMessage: 'oui',
                    description: 'ok',
                }),
                cancelText: formatMessage({
                    id: 'factoty_form_drawer.before_close.cancel',
                    defaultMessage: 'annuler',
                    description: 'cancel',
                }),
            });
        } else {
            onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
        }
    };
    useEffect(() => {
        if (factoryId) {
            loadFactory({ id: factoryId });
        }
    }, [loadFactory, factoryId]);
    useEffect(() => {
        if (previous?.factoryDetailState.loading && !factoryDetailState.loading) {
            if (factoryDetailState.error || !factoryDetailState.data) {
                errorMessage({
                    content: formatMessage({
                        id: 'factoty_form_drawer.error_detail_message',
                        defaultMessage: 'Une erreur est survenue pendant la récupération de la factory',
                        description: 'Factory Error Message',
                    }),
                });
            } else {
                form.setFieldsValue(factoryDetailState.data);
            }
        }
    }, [previous?.factoryDetailState.loading, factoryDetailState, form, formatMessage]);

    useEffect(() => {
        if (previous?.factoryUpdateState.loading && !factoryUpdateState.loading) {
            if (factoryUpdateState.error || !factoryUpdateState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'factoty_form_drawer.success_edit_message',
                        defaultMessage: 'Usine éditée avec succès',
                        description: 'Factory Success Message',
                    }),
                });
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
    }, [previous?.factoryUpdateState.loading, factoryUpdateState, formatMessage, onClose]);
    useEffect(() => {
        if (previous?.factoryCreateState.loading && !factoryCreateState.loading) {
            if (factoryCreateState.error || !factoryCreateState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'factoty_form_drawer.success_add_message',
                        defaultMessage: 'Usine créée avec succès',
                        description: 'Factory Success Message',
                    }),
                });
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
    }, [previous?.factoryCreateState.loading, factoryCreateState, formatMessage, onClose]);

    return (
        <Drawer
            width={500}
            title={
                factoryId
                    ? formatMessage({
                          id: 'factoty_form_drawer.title.edit',
                          defaultMessage: "Modifier l'usine",
                          description: 'Drawer title',
                      })
                    : formatMessage({
                          id: 'factoty_form_drawer.title.add',
                          defaultMessage: 'Ajouter une usine',
                          description: 'Drawer title',
                      })
            }
            onClose={onBeforeClose}
            afterVisibleChange={onAfterVisibleChange}
            {...props}
        >
            <Spin spinning={factoryCreateState.loading || factoryUpdateState.loading}>
                <Form
                    form={form}
                    onFinish={onFormValidSubmit}
                    layout="vertical"
                    requiredMark={false}
                    initialValues={{ contacts: [{}] }}
                    scrollToFirstError
                >
                    <Form.Item
                        label={formatMessage({
                            id: 'factoty_form_drawer.form.item.label.name',
                            defaultMessage: "Nom de l'usine",
                            description: 'field label',
                        })}
                        rules={[requiredRule]}
                        name="name"
                    >
                        <Input
                            placeholder={formatMessage({
                                id: 'factoty_form_drawer.form.item.input.name',
                                defaultMessage: 'Saisir le nom',
                                description: 'field placeholder',
                            })}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label={formatMessage(genericMessages.dunsNumber)} rules={[requiredRule]} name="duns">
                        <Input
                            placeholder={formatMessage({
                                id: 'subcontractor_form_drawer.form.item.contact.input.phone',
                                defaultMessage: 'Saisir un numéro',
                                description: 'input placeholder',
                            })}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        label={
                            <LabelWithTooltipIcon
                                label={formatMessage({
                                    id: 'factoty_form_drawer.form.item.label.address',
                                    defaultMessage: 'Adresse de fabrication',
                                    description: 'input label',
                                })}
                                tooltip={formatMessage({
                                    id: 'factoty_form_drawer.form.item.tooltip.address',
                                    defaultMessage: "Adresse à laquelle se trouvent les ateliers de l'usine",
                                    description: 'input help tooltip',
                                })}
                            />
                        }
                        rules={[requiredRule]}
                        name={['address', 'street']}
                    >
                        <Input
                            placeholder={formatMessage({
                                id: 'factoty_form_drawer.form.item.input.address',
                                defaultMessage: 'Saisir une adresse',
                                description: 'field placeholder',
                            })}
                            size="large"
                        />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col xs={9}>
                            <Form.Item
                                name={['address', 'zipCode']}
                                label={formatMessage({
                                    id: 'factoty_form_drawer.form.item.label.zipCode',
                                    defaultMessage: 'Code postal',
                                    description: 'input label',
                                })}
                                rules={[requiredRule]}
                            >
                                <Input
                                    placeholder={formatMessage({
                                        id: 'factoty_form_drawer.form.item.input.zipCode',
                                        defaultMessage: 'Saisir un code postal',
                                        description: 'input placeholder',
                                    })}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={15}>
                            <Form.Item
                                name={['address', 'city']}
                                label={formatMessage({
                                    id: 'factoty_form_drawer.form.item.label.city',
                                    defaultMessage: 'Ville',
                                    description: 'input label',
                                })}
                                rules={[requiredRule]}
                            >
                                <Input
                                    placeholder={formatMessage({
                                        id: 'factoty_form_drawer.form.item.input.city',
                                        defaultMessage: 'Saisir une ville',
                                        description: 'input placeholder',
                                    })}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name={['address', 'country']}
                        label={formatMessage({
                            id: 'factoty_form_drawer.form.item.label.country',
                            defaultMessage: 'Pays',
                            description: 'input label',
                        })}
                        rules={[requiredRule]}
                    >
                        <CountrySelect
                            placeholder={formatMessage({
                                id: 'factoty_form_drawer.form.item.select.country',
                                defaultMessage: 'Sélectionner un pays',
                                description: 'input placeholder',
                            })}
                            size="large"
                        />
                    </Form.Item>
                    <Divider />
                    <TitleAlt level={4}>
                        <FormattedMessage
                            id="factoty_form_drawer.form.item.title.contact_usine"
                            defaultMessage="Contact(s) au sein de l'usine"
                            description="form section title"
                        />
                    </TitleAlt>
                    <Form.List name="contacts">
                        {(fields, { add, remove }, { errors }) => (
                            <Card
                                className={classNames(
                                    'form-list-wrapper',
                                    fields.length > 1 && 'has-more-than-one-field'
                                )}
                                bordered={false}
                                size="small"
                                style={{ marginBottom: '1.5rem' }}
                            >
                                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                    <Card
                                        className={classNames(
                                            'form-list-wrapper',
                                            fields.length > 1 && 'has-more-than-one-field'
                                        )}
                                        bordered={false}
                                        size="small"
                                        key={key}
                                        style={{ marginBottom: '1.5rem' }}
                                    >
                                        {fields.length > 1 && (
                                            <div className="flex flex-between" style={{ marginBottom: '1rem' }}>
                                                <Typography.Paragraph style={{ margin: 0 }}>
                                                    <p>
                                                        {formatMessage(
                                                            {
                                                                id: 'factoty_form_drawer.form.item.contact.label',
                                                                defaultMessage: 'Contact n°{index}',
                                                                description: 'label',
                                                            },
                                                            { index: index + 1 }
                                                        )}
                                                    </p>
                                                </Typography.Paragraph>
                                                <ButtonIcon onClick={() => remove(name)} icon={<DeleteOutlined />} />
                                            </div>
                                        )}
                                        <Row gutter={24}>
                                            <Col xs={12}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'lastName']}
                                                    fieldKey={[fieldKey, 'lastName']}
                                                    label={formatMessage({
                                                        id: 'factoty_form_drawer.form.item.contact.label.lastname',
                                                        defaultMessage: 'Nom',
                                                        description: 'input label',
                                                    })}
                                                    rules={[requiredRule]}
                                                >
                                                    <Input
                                                        placeholder={formatMessage({
                                                            id: 'factoty_form_drawer.form.item.contact.input.lastname',
                                                            defaultMessage: 'Saisir un nom',
                                                            description: 'input placeholder',
                                                        })}
                                                        size="large"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'firstName']}
                                                    fieldKey={[fieldKey, 'firstName']}
                                                    label={formatMessage({
                                                        id: 'factoty_form_drawer.form.item.contact.label.firstname',
                                                        defaultMessage: 'Prénom',
                                                        description: 'input label',
                                                    })}
                                                    rules={[requiredRule]}
                                                >
                                                    <Input
                                                        placeholder={formatMessage({
                                                            id: 'factoty_form_drawer.form.item.contact.input.firstname',
                                                            defaultMessage: 'Saisir un prénom',
                                                            description: 'input placeholder',
                                                        })}
                                                        size="large"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            name={[name, 'email']}
                                            fieldKey={[fieldKey, 'email']}
                                            label={formatMessage({
                                                id: 'factoty_form_drawer.form.item.contact.label.email',
                                                defaultMessage: 'Adresse e-mail',
                                                description: 'input label',
                                            })}
                                            rules={[
                                                requiredRule,
                                                { type: 'email', message: formatMessage(formMessages.invalidEmail) },
                                            ]}
                                        >
                                            <Input
                                                placeholder={formatMessage({
                                                    id: 'factoty_form_drawer.form.item.contact.input.email',
                                                    defaultMessage: 'Saisir une adresse e-mail',
                                                    description: 'input placeholder',
                                                })}
                                                size="large"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name={[name, 'phone']}
                                            fieldKey={[fieldKey, 'phone']}
                                            label={formatMessage({
                                                id: 'factoty_form_drawer.form.item.contact.label.phone',
                                                defaultMessage: 'Téléphone fixe',
                                                description: 'input label',
                                            })}
                                        >
                                            <PhoneInput
                                                placeholder={formatMessage({
                                                    id: 'factoty_form_drawer.form.item.contact.input.phone',
                                                    defaultMessage: 'Saisir un numéro',
                                                    description: 'input placeholder',
                                                })}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name={[name, 'job']}
                                            fieldKey={[fieldKey, 'job']}
                                            label={formatMessage({
                                                id: 'factoty_form_drawer.form.item.contact.label.job',
                                                defaultMessage: 'Poste / Titre',
                                                description: 'input label',
                                            })}
                                            rules={[requiredRule]}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Input
                                                placeholder={formatMessage({
                                                    id: 'factoty_form_drawer.form.item.contact.input.job',
                                                    defaultMessage: 'Saisir le poste / titre',
                                                    description: 'input placeholder',
                                                })}
                                                size="large"
                                            />
                                        </Form.Item>
                                    </Card>
                                ))}
                                <Form.Item className="form-list-add-more">
                                    <Button type="default" shape="round" size="small" onClick={() => add()} block>
                                        <FormattedMessage
                                            id="factoty_form_drawer.form.item.contact.button.add"
                                            defaultMessage="Ajouter un contact supplémentaire"
                                            description="dynamic form item add-more button"
                                        />
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </Card>
                        )}
                    </Form.List>
                    <Divider />
                    {getWorkerFields(formatMessage).map((field) => (
                        <Form.Item
                            name={field.name}
                            key={field.name}
                            label={<LabelWithTooltipIcon label={field.label} tooltip={field.tooltip} />}
                            rules={[
                                requiredRule,
                                ({ getFieldValue }) => ({
                                    async validator(_, value) {
                                        if (
                                            ['nbWorkers', 'nbPermanentWorkers', 'nbTemporaryWorkers'].includes(
                                                field.name
                                            )
                                        ) {
                                            if (
                                                getFieldValue('nbWorkers') &&
                                                getFieldValue('nbPermanentWorkers') &&
                                                getFieldValue('nbTemporaryWorkers') &&
                                                Number(getFieldValue('nbPermanentWorkers')) +
                                                    Number(getFieldValue('nbTemporaryWorkers')) !==
                                                    Number(getFieldValue('nbWorkers'))
                                            ) {
                                                return await Promise.reject(formatMessage(formMessages.errorNbWorker));
                                            } else {
                                                return await Promise.resolve();
                                            }
                                        } else if (
                                            [
                                                'nbWorksBeginYear',
                                                'nbNewPermanantsWorkers',
                                                'nbPermanentWorkersLeaving',
                                            ].includes(field.name)
                                        ) {
                                            return await Promise.resolve();
                                        } else {
                                            if (
                                                getFieldValue('nbWorkers') &&
                                                value &&
                                                getFieldValue('nbWorkers') < value
                                            ) {
                                                return await Promise.reject(
                                                    formatMessage(formMessages.errorNbWorkerTotal)
                                                );
                                            } else {
                                                return await Promise.resolve();
                                            }
                                        }
                                    },
                                }),
                            ]}
                        >
                            <InputNumber
                                placeholder={formatMessage({
                                    id: 'factoty_form_drawer.form.item.getWorkerFields.input.number',
                                    defaultMessage: 'Saisir un nombre',
                                    description: 'input placeholder',
                                })}
                                min={0}
                                size="large"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    ))}
                    <Button
                        type="primary"
                        htmlType="submit"
                        shape="round"
                        size="large"
                        loading={factoryUpdateState.loading || factoryCreateState.loading}
                        block
                    >
                        {factoryId
                            ? formatMessage({
                                  id: 'factoty_form_drawer.form.item.button.edit',
                                  defaultMessage: 'Enregistrer les modifications',
                                  description: 'form submit button',
                              })
                            : formatMessage({
                                  id: 'factoty_form_drawer.form.item.button.add',
                                  defaultMessage: "Ajouter l'usine",
                                  description: 'form submit button',
                              })}
                    </Button>
                    <p style={{ margin: '1.5rem 0 0', textAlign: 'center' }}>
                        <RequiredFields />
                    </p>
                </Form>
            </Spin>
        </Drawer>
    );
};

export default FactoryFormDrawer;
